import { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../swiperStyles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Gallery = () => {
    //Array that contains images

    const [slidesPerView, setSlidesPerView] = useState(3);
    const [slidesPerGroup, setSlidesPerGroup] = useState(3);
    const imageSlides = [];

    for (let i = 0; i < 110; i++) {
        imageSlides.push({
            image: `http://arweave.net/qfRWtjzO1xi4lUf-0MxFViPxyzW2qOIrHNDopq8XJQA/ghostie${
                i + 1
            }.png`,
            key: `slide-${i + 1}`,
        });
    }
    var x = window.matchMedia("(max-width: 768px)");
    function myFunction(x) {
        if (x.matches) {
            setSlidesPerView(1);
            setSlidesPerGroup(1);
        } else {
            setSlidesPerView(3);
            setSlidesPerGroup(3);
        }

    }
    useEffect(() => {
        myFunction(x);
    }, []);

    x.addListener(myFunction);

    return (
        <>
            <h2 className="titleCollection" data-aos="fade-down" data-aos-once="false">GHOSTIES</h2>
            <div className="swiperBackground">
                <div className="containerTitle">
                    <h2 className="titleCollectionBottom" data-aos="fade-up" data-aos-once="false">COLLECTION</h2>
                </div>

                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={30}
                    slidesPerGroup={slidesPerGroup}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper">
                    {imageSlides.map((image) => (
                        <SwiperSlide key={image.key}>
                            <img src={image.image} alt={image.key} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
};
export default Gallery;
