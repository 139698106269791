const Story = () => {
  return (
    <section className="story">
      <h2 data-aos="fade-in" data-aos-duration="800" data-aos-once="false">THE LEGEND SAYS</h2>
      <p data-aos="fade-in" data-aos-duration="1200" data-aos-once="false">
        After several battles and tough times, seeking for a better future as a
        society, there were only
        <span className="cyan-text"> 111 brave victorious in Soul City. </span>
        These souls, transcended and became
        <span className="red-text"> Ghosties</span>, with the purpose of
        conquest Aptos’s world. In order to make this happen, they knew that
        Ghosties must work as a team to grow together as a whole and support
        everyone in the process.
        <span className="cyan-text"> This was the key to success. </span>
        Nonetheless, they weren’t the only ones wanting to conquer Aptos. They
        knew it, and to make the difference, they became a brotherhood, linking
        up with bright minds, with their focus on building, and adding value to
        the society. That's the way to make
        <span className="cyan-text"> Aptos a better place to be. </span>
      </p>
    </section>
  );
};

export default Story;
