const Header = () => {
    return (
        <>
            <div className="overlay">
                <h1 className="title" data-aos="zoom-out" data-aos-duration="1000" data-aos-once="false">
                    Aptos <p className="block">Ghosties</p>
                </h1>
            </div>
            <video
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                className="video"
                src={require("../assets/vids/intento.mov")}
            />
        </>
    );
};

export default Header;
