const Navbar = () => {
    return (
        <nav data-aos="fade-in" data-aos-duration="1000" data-aos-once="false">
   
                <img
                    id="ghosties-logo"
                    src={require("../assets/imgs/ghosties_logo.png")}
                    alt=""
                />
                <a href="https://twitter.com/AptosGhosties">
                    <img
                        id="twitter-logo"
                        src={require("../assets/imgs/twitter-logo.png")}
                        alt=""
                    />
                </a>

        </nav>
    );
};

export default Navbar;
