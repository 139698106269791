const MisionVision = () => {
  return (
    <section className="MisionVision">
      <div className="container" id="containerMV">
        
        <div className="col" data-aos="zoom-out" data-aos-duration="1000" data-aos-once="false" id="colMV">
          <h2>VISION</h2>
          <p> <span>-</span> Create an exlusive community</p>
          <p><span>-</span> Help each other to win</p>
          <p><span>-</span> Networking</p>
          <p><span>-</span> Unite Aptos</p>
          <p><span>-</span> Innovate</p>
        </div>

        <div className="col" id="colMV" data-aos="zoom-out" data-aos-duration="1000" data-aos-once="false" > 
          <h2>MISSION</h2>
          
          <p className = "misionClass">
            The focus is on our members, offering exellent quality services,
            exlusivity, and making Aptos a better place.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MisionVision;
