const Crew = () => {
  return (
    <div className="crew">
      <h2> CREW</h2>

      <div className="crewCardContainer" data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
        <section className="crewCard">
          <img src={require("../assets/imgs/kingBoo.png")} alt="" />
          <span> Co-Founder </span>
          <p> King Boo </p>
          <p className="crewDesc" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
            Dynamic leader and co-founder of Ghosties, expert in community
            management and cryptocurrency.
          </p>
        </section>

        <section className="crewCard">
          <img src={require("../assets/imgs/redSoul.png")} alt="" />
          <span> Co-Founder </span>
          <p> Red Soul </p>
          <p className="crewDesc" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
            Visionary co-founder of Ghosties, experienced in web2 and web3
            technologies.
          </p>
        </section>

        <section className="crewCard">
          <img src={require("../assets/imgs/ghostWar.png")} alt="" />
          <span> Developer </span>
          <p> BrainEater </p>
          <p className="crewDesc" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
            BrainEater is like a zombie, but instead of brains, they're
            constantly devouring knowledge.
          </p>
        </section>
      </div>

      <div className="crewCardContainer" data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
        <section className="crewCard">
          <img src={require("../assets/imgs/ghostPop.png")} alt="" />
          <span> Developer </span>
          <p> RulerBoo </p>
          <p className="crewDesc" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
           Outside of a dog, code is man's best friend, Inside of the dog is too small to code.
          </p>
        </section>

        <section className="crewCard" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
          <img src={require("../assets/imgs/ghostNata.png")} alt="" />
          <span> Artist </span>
          <p> Zenik </p>
          <p className="crewDesc" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">Leading the world thru my vision of design and creativity.</p>
        </section>

        <section className="crewCard" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
          <img src={require("../assets/imgs/ghostElav.png")} alt="" />
          <span> Artist </span>
          <p> Valkylav </p>
          <p className="crewDesc" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="false">
            The world needs more people that love what they do.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Crew;
