import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Story from "./components/Story";
import Gallery from "./components/Gallery";
import MisionVision from "./components/MisionVision";
import WhatsNew from "./components/WhatsNew";
import Crew from "./components/Crew";
import Footer from "./components/Footer";

function App() {
    return (
        
        <div className="App">
            <Navbar />
            <Header />
            <Story />
            <Gallery />
            <MisionVision />
            <WhatsNew />
            <Crew />
            <Footer />
        </div>
    );
}

export default App;
