const WhatsNew = () => {
    return (
        <div className="whatsNew">
            <h2> WHAT'S NEW </h2>

            <div className="whatsNewCards">
                <section
                    className="whatsNewCard"
                    data-aos="zoom-in"
                    data-aos-duration="1400"
                    data-aos-once="false">
                    <div>
                        <img
                            src={require("../assets/imgs/reference_ghost.png")}
                            alt=""
                        />
                        <p className="whatsNewCardp"> Project</p>
                    </div>
                    <p>
                        {" "}
                        Being part of the Ghosties group within Aptos ecosystem
                        signifies that you are part of an elite, limited
                        collection. Holding a Ghostie grants prestige and opens
                        doors to various opportunities such as exclusive
                        services, utilities, and other perks. This will lead to
                        the strengthening and unifying of the community,
                        creating a tight-knit group that can be referred to as a
                        family.
                    </p>
                </section>

                <section
                    className="whatsNewCard"
                    data-aos="zoom-in"
                    data-aos-duration="1400"
                    data-aos-once="false">
                    <div>
                        <img
                            src={require("../assets/imgs/community_vector.png")}
                            alt=""
                        />
                        <p> Community</p>
                    </div>
                    <p>
                        {" "}
                        Ghosties prioritizes the needs and wants of its members
                        above all else. This can include offering a wide range
                        of high-quality services and amenities that cater to
                        your needs, as well as providing exclusive access to new
                        projects or business opportunities.
                    </p>
                    <p>
                        Additionally, Ghosties will also strive to make the
                        community a better place through various initiatives or
                        programs that benefit its members and the wider
                        community.{" "}
                    </p>

                    <p>
                        Overall, the goal is to create a positive and fulfilling
                        experience for Ghosties members, which in turn helps to
                        build a strong and supportive community.
                    </p>
                </section>

                <section
                    className="whatsNewCard"
                    data-aos="zoom-in"
                    data-aos-duration="1400"
                    data-aos-once="false">
                    <div>
                        <img
                            src={require("../assets/imgs/reference_labs.png")}
                            alt=""
                        />
                        <p> Ghosties Labs</p>
                    </div>
                    <p>
                        In Ghosites, we believe that the minting journey is not
                        just the click of a button and receiving your NFT. We
                        want to make the difference, and we know that other
                        projects want that too. Therefore, the Ghosties
                        Incubator is created, to bring an enhanced experience
                        for NFT enthusiasts and upcoming projects.
                    </p>

                    <p>
                        <ul>
                            <h3>General info</h3>
                            <ul>
                                <li>
                                    For every project developed by ghosties, all
                                    members will have guaranteed WL spots.
                                </li>
                                <li>
                                    Each member of Ghosties will be granted
                                    access to airdrops and gifts for every
                                    project we develop.
                                </li>
                                <li>
                                    All Ghosties that have advisory experience,
                                    will be considered to be included in the
                                    projects developed as advisors, and be
                                    rewarded accordingly.
                                </li>
                            </ul>
                            <h3>Services</h3>
                            <ul>
                                <li>
                                    Gamified/Animated minting experience
                                    multichain.
                                </li>
                                <li>
                                    Art ideation and production for projects who
                                    don't have artists.
                                </li>
                                <li>
                                    Design and development of the project’s mint
                                    site and smart contract
                                </li>
                            </ul>
                        </ul>
                    </p>
                </section>

                <section
                    className="whatsNewCard"
                    data-aos="zoom-in"
                    data-aos-duration="1400"
                    data-aos-once="false">
                    <div>
                        <img
                            src={require("../assets/imgs/reference_relationship.png")}
                            alt=""
                        />
                        <p> Partners</p>
                    </div>
                    <h3>
                        {" "}
                        <a
                            className="link"
                            href={"https://tsunami.finance/"}
                            target="_blank"
                            rel="noreferrer">
                            {" "}
                            Tsunami Finance
                        </a>{" "}
                    </h3>
                </section>
            </div>
        </div>
    );
};

export default WhatsNew;
